.cancelModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000;
}
.cancelModal .cancelModalContent {
  background-color: #333;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cancelModal .cancelModalContent h2 {
  margin-top: 0;
  margin-bottom: 10px;
}
.cancelModal .cancelModalContent p {
  margin-top: 10px;
}
.cancelModal .cancelModalContent button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.cancelModal .cancelModalContent button:hover {
  background-color: #c82333;
}