.hero {
  width: 90%;
  margin: 0 auto;
  color: white;
  max-width: 1200px;
  margin-inline: auto;
}
.hero p {
  margin-bottom: 2rem;
}
.hero p:last-child {
  margin-bottom: unset;
}
.hero .hero1 {
  display: grid;
  flex-direction: column;
  grid-template-columns: 1fr;
  place-content: start;
}
.hero .hero1 .fox {
  width: auto;
  max-width: 80vw;
  display: block;
  margin-inline: auto;
}
.hero .hero1 .section-1 {
  text-align: start;
  padding-left: 0.5rem;
  padding-top: 6rem;
}
.hero .hero1 .section-1 button {
  background: linear-gradient(87deg, #0085ff -11.82%, #001aff 97.89%);
  margin-top: 2rem;
  display: block;
  margin-inline: auto;
  padding: 0.7rem 2.5rem;
  outline: none;
  border: none;
  border-radius: 50px;
  font-weight: 500;
  font-size: 1.1rem;
  cursor: pointer;
}
.hero .hero1 .section-1 .heading-1 {
  width: -moz-fit-content;
  width: fit-content;
  text-align: start;
  align-self: flex-start;
  width: 100%;
  max-width: 500px;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  margin-inline: auto;
}
.hero .hero1 .section-1 .heading-2 {
  width: -moz-fit-content;
  width: fit-content;
  text-align: start;
  align-self: flex-start;
  width: 100%;
  max-width: 500px;
  font-size: 1.4rem;
  text-align: center;
  margin-inline: auto;
}
.hero .hero1 .section-1 img {
  width: 100%;
  max-width: 600px;
  margin-top: 2rem;
  border-radius: 10px;
  display: block;
  margin-inline: auto;
  margin-bottom: 2rem;
}
.hero .hero1 .section-1 > div {
  margin: 0 3rem;
}
.hero .hero1 .heading {
  width: -moz-fit-content;
  width: fit-content;
  text-align: start;
  align-self: flex-start;
  width: 90%;
  max-width: 400px;
}
.hero .hero1 .heading2 {
  font-size: 1.3rem;
  font-family: "Tenor Sans", sans-serif;
}
.diamond-line {
  width: 108%;
  position: relative;
  left: -10px;
}
.hero .hero1 .section {
  text-align: start;
  padding-left: 0.5rem;
}
.hero2 .splrg-images {
  display: inline-grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  place-items: center;
  row-gap: 0.5rem;
  margin: auto;

  img {
    margin: unset;
    max-width: 90%;
    display: inline-block;
  }
}

.hero2 {
  display: grid;
  place-content: center;
}
.hero2 .section {
  width: 60%;
  text-align: start;
  margin: 2rem auto;
}
.hero2 .section a {
  display: inline;
}
.hero2 .credit {
  width: 80%;
  max-width: 400px;
  margin-inline: auto;
}
.hero2 .shirt {
  display: block;
  width: 80%;
  max-width: 400px;
  margin-inline: auto;
  margin-top: 2rem;
  border-radius: 10px;
}
.hero2 button {
  background: linear-gradient(87deg, #0085ff -11.82%, #001aff 97.89%);
  margin-top: 2rem;
  display: block;
  margin-inline: auto;
  padding: 0.7rem 2.5rem;
  outline: none;
  border: none;
  border-radius: 50px;
  font-weight: 500;
  font-size: 1.1rem;
  cursor: pointer;
}
.hero2 .fox-meme {
  display: block;
  width: 80%;
  margin-top: 2rem;
  margin-inline: auto;
  max-width: 500px;
}
.hero2 img {
  max-width: 80%;
  margin: auto;
  margin-top: 2rem;
}
.hero2 img.big-image {
  max-width: 80%;
}
.hero2 p {
  margin-bottom: 1rem;
}
.hero2 ul {
  display: inline-block;
  width: max-content;

  li span {
    display: inline-block;
    text-align: center;

    &.w-1 {
      width: 1em;
    }
    &.w-2 {
      width: 2em;
    }
    &.w-7 {
      width: 4em;
    }
  }
}

.heading {
  margin-top: 1.5rem;
  font-size: 1.25rem;
  font-weight: bold;
  text-transform: uppercase;
}

.heading-big {
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

@media screen and (min-width: 700px) {
  .hero .hero1 {
    align-items: start;
    grid-template-columns: 60% 40%;
  }
  .hero .hero1 .heading .heading2 {
    font-size: 1.5rem;
  }
  .hero .hero1 .section {
    margin-top: 1rem;
    padding-left: 2rem;
  }

  .hero2 img {
    max-width: 40%;
  }
  .hero2 .heading {
    margin-top: 3rem;
  }
}
