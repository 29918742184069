.walletModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000;
}
.walletModal .walletModalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #222;
  color: #fff;
  padding: 20px;
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;
}
.walletModal .walletModalContent .closeIcon {
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
  font-size: 24px;
  color: #ffffff;
}
.walletModal .walletModalContent .closeIcon:hover {
  color: #7289da;
}
.walletModal .walletModalContent h2 {
  margin-top: 0;
  margin-bottom: 10px;
}
.walletModal .walletModalContent p {
  margin-bottom: 15px;
}
.walletModal .walletModalContent .walletAddress {
  background-color: #333;
  padding: 10px;
  margin-bottom: 15px;
  word-break: break-all;
  border: 1px dashed #555;
}
.walletModal .walletModalContent .seedPhrase {
  background-color: #333;
  padding: 10px;
  margin-bottom: 15px;
  word-break: break-all;
}
.walletModal .walletModalContent input[type=text] {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  color: black;
}
.walletModal .walletModalContent .errorMessage {
  color: #ff6b6b;
}
.walletModal .walletModalContent button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
}
.walletModal .walletModalContent button:hover {
  background-color: #43a047;
}