.overlay {
  background: rgba(0, 0, 0, 0.85);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modal {
  background: #1e1e1e;
  color: #ccc;
  border-radius: 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  padding: 30px;
  max-width: 400px;
  width: 90%;
  text-align: center;
  position: relative;
}
.modal h2 {
  margin-top: 0;
  color: #fff;
  font-size: 1.8em;
  margin-bottom: 20px;
}
.modal p {
  font-size: 1em;
  line-height: 1.5;
  color: #d9d9d9;
  margin-bottom: 20px;
}
.modal .emailInput {
  width: calc(100% - 20px);
  margin: 20px 0;
  padding: 10px;
  border: 1px solid #555;
  background-color: #2a2a2a;
  color: #fff;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 1em;
}
.modal .signInLinkButton {
  width: calc(100% - 40px);
  margin: 10px 20px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease, transform 0.2s ease;
  background-color: #4caf50;
  color: white;
}
.modal .signInLinkButton:hover {
  transform: translateY(-2px);
}
.modal .closeIcon {
  position: absolute;
  left: 15px;
  top: 15px;
  font-size: 1.5em;
  color: #ccc;
  cursor: pointer;
}
.modal .closeIcon:hover {
  color: #fff;
}

.modal.verificationSent h2 {
  color: #76ff03;
}
.modal.verificationSent p {
  max-width: 280px;
  margin: 0 auto 20px;
}

.googleSignInBtn {
  width: calc(100% - 40px);
  background-color: #4285F4;
  color: white;
  border: none;
  margin: 10px 20px;
  padding: 10px;
  font-size: 1em;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s ease;
}
.googleSignInBtn:hover {
  background-color: #3367D6;
}
.googleSignInBtn .googleIcon {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}
.googleSignInBtn .buttonText {
  font-weight: bold;
}