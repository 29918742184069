.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000;
}

.modal {
  background: #2c2f33;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 400px;
  text-align: center;
  position: relative;
  color: #ffffff;
}
.modal h2 {
  margin: 0 0 16px;
  color: #ffffff;
  font-size: 24px;
}
.modal p {
  margin-bottom: 24px;
  color: #bbbbbb;
  font-size: 16px;
}
.modal .buttonGroup {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}
.modal .button {
  padding: 10px 20px;
  border: 1px solid #3a3f44;
  background-color: transparent;
  color: #ffffff;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, border-color 0.3s;
}
.modal .button:hover, .modal .button.active {
  background-color: #7289da;
  border-color: #7289da;
}
.modal .input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #555;
  background-color: #23272a;
  color: #ffffff;
  border-radius: 4px;
  font-size: 16px;
}
.modal .submitButton {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: none;
  background-color: #43b581;
  color: #ffffff;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}
.modal .submitButton:hover {
  background-color: #369f67;
}
.modal .closeIcon {
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
  font-size: 24px;
  color: #ffffff;
}
.modal .closeIcon:hover {
  color: #7289da;
}

.errorMsg {
  color: #ff6b6b;
  margin: 10px 0;
}

.submitButton:disabled {
  background-color: #3a3f44;
  cursor: not-allowed;
}

/* Add this to give visual feedback to the user when the link button is clicked */
.submitButton:not(:disabled):active {
  background-color: #2e7d32;
}

/* Style for the success message */
.successMsg {
  color: #76ff03;
  margin: 10px 0;
}