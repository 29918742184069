.checkoutModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000;
}
.checkoutModal .checkoutModalContent {
  background-color: #2a2a2a;
  color: #fff;
  padding: 20px 50px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  width: 90%;
  max-width: 500px;
  position: relative;
  height: 90%;
  max-height: 800px;
  overflow: auto;
  /* Additional styles for the divider text, if needed */
}
.checkoutModal .checkoutModalContent .walletbutton {
  width: 100%;
  color: white;
  display: flex;
  justify-content: center;
}
.checkoutModal .checkoutModalContent .closeIcon {
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
  font-size: 24px;
  color: #ffffff;
}
.checkoutModal .checkoutModalContent .closeIcon:hover {
  color: #7289da;
}
.checkoutModal .checkoutModalContent h2 {
  margin-top: 0;
  margin-bottom: 5px;
}
.checkoutModal .checkoutModalContent p,
.checkoutModal .checkoutModalContent ul {
  margin-bottom: 15px;
}
.checkoutModal .checkoutModalContent li {
  margin-bottom: 15px;
}
.checkoutModal .checkoutModalContent li span {
  font-size: 0.9em;
  color: #bbb;
}
.checkoutModal .checkoutModalContent .primaryButton {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 20px;
}
.checkoutModal .checkoutModalContent .primaryButton:hover {
  background-color: #43a047;
}
.checkoutModal .checkoutModalContent .promo {
  display: flex;
  gap: 1rem;
  padding-bottom: 1rem;
}
.checkoutModal .checkoutModalContent .loading {
  margin-inline: auto;
}
.checkoutModal .checkoutModalContent .divider {
  display: flex;
  align-items: center;
  text-align: center;
}
.checkoutModal .checkoutModalContent .divider::before,
.checkoutModal .checkoutModalContent .divider::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid white;
  /* Adjust the color as needed */
}
.checkoutModal .checkoutModalContent .divider::before {
  margin-right: 10px;
}
.checkoutModal .checkoutModalContent .divider::after {
  margin-left: 10px;
}
.checkoutModal .checkoutModalContent .dividerText {
  padding: 0 10px;
}
.checkoutModal .checkoutModalContent .currencySelection {
  margin-bottom: 20px;
}
.checkoutModal .checkoutModalContent .currencySelection label {
  display: block;
  margin-bottom: 5px;
  font-size: 1em;
  color: #fff;
}
.checkoutModal .checkoutModalContent .currencySelection select {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  background-color: #333;
  color: #fff;
  border: 1px solid #444;
  font-size: 1em;
  cursor: pointer;
}
.checkoutModal .checkoutModalContent .currencySelection select:focus {
  outline: none;
  border-color: #7289da;
}

input {
  border: 0.5px solid #ffffff;
  background-color: transparent;
  color: white;
  padding: 0.2rem;
  text-align: center;
  font-weight: 400;
  font-size: 0.7rem;
  outline: none;
  border-radius: 5px;
  font-size: 1rem;
  width: 80%;
  max-width: 200px;
}

.quantityInput {
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 0.5rem;
  margin: 0 1rem;

  label {
    padding: 0.2rem;
    width: 4.5rem;
    text-align: center;

    border: 0.5px solid #ffffff;
    border-radius: 5px;
    cursor: pointer;

    &:has(input:checked) {
      background-color: var(--indigo-11);
    }

    input {
      display: none;
    }
  }
}
