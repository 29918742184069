.footer {
  width: 90%;
  margin: 0 auto;
}
.footer .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.footer .top img {
  flex-grow: 1;
  max-width: 100%;
  margin-top: 0;
}
.footer .top .heading {
  text-transform: uppercase;
  white-space: nowrap;
  flex: 0 0 auto;
}
.footer .bottom {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
.footer .bottom .text {
  width: 90%;
  font-size: 0.8rem;
  padding-left: 1rem;
}
.footer .bottom .socials-faq .socials {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}
.footer .bottom .socials-faq .socials a img {
  max-width: 25px;
}
.footer .rights {
  text-align: end;
  font-size: 0.6rem;
  padding: 1rem 0;
}
.footer .arrow1,
.footer .arrow2 {
  margin-top: 0.5rem;
  width: 100%;
}

@media screen and (min-width: 700px) {
  .footer .top .heading {
    font-size: 1.5rem;
  }
  .footer .bottom {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    -moz-column-gap: 1rem;
         column-gap: 1rem;
  }
  .footer .bottom .text {
    font-size: 1rem;
    max-width: 500px;
  }
  .footer .bottom .socials-faq {
    padding-right: 2rem;
  }
  .footer .bottom .socials-faq .socials {
    -moz-column-gap: 2rem;
         column-gap: 2rem;
  }
  .footer .bottom .socials-faq .socials a img {
    max-width: 40px;
  }
}