.navbar {
  width: 100%;
  padding: 1rem 4%;
  margin-inline: auto;
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  background: rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(7.5px);
          backdrop-filter: blur(7.5px);
  position: sticky;
  top: 0;
  z-index: 2;
}
.navbar .logo {
  width: 5%;
  min-width: 30px;
  cursor: pointer;
}
.navbar .logo img {
  width: 100%;
}
.navbar .links {
  display: flex;
  justify-content: end;
  -moz-column-gap: 5%;
       column-gap: 5%;
  align-items: center;
}
.navbar .links a {
  color: white;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  font-size: 0.8rem;
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.6);
}
.navbar .user-info {
  position: relative;
}
.navbar .user-info .user-icon {
  font-size: 2rem;
  cursor: pointer;
  color: white;
}
.navbar .user-info .dropdown-menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 100%;
  right: 0;
  background-color: #333;
  color: #fff;
  border: 1px solid #555;
  border-radius: 6px;
  padding: 15px;
  width: 300px;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}
.navbar .user-info .dropdown-menu .logout-button, .navbar .user-info .dropdown-menu .link-wallet-button {
  background-color: #d32f2f;
  color: white;
  border: none;
  padding: 10px 15px;
  margin-top: 10px;
  border-radius: 4px;
  font-size: 0.9em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.navbar .user-info .dropdown-menu .logout-button:hover, .navbar .user-info .dropdown-menu .link-wallet-button:hover {
  background-color: #b71c1c;
}
.navbar .user-info .dropdown-menu .link-wallet-button {
  background-color: #4caf50;
}
.navbar .user-info .dropdown-menu .link-wallet-button:hover {
  background-color: #388e3c;
}
.navbar .user-info .dropdown-menu p {
  margin: 10px 0;
  font-size: 0.8rem;
  line-height: 1.4;
  word-break: break-all;
}
.navbar .user-info .dropdown-menu p:first-child {
  font-weight: 600;
}
.navbar .user-info .dropdown-menu:after {
  content: "";
  position: absolute;
  bottom: 100%;
  right: 10px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent #333 transparent;
}

.get-started {
  white-space: nowrap;
  max-width: 120px;
  text-align: center;
  color: white;
  border: none;
  padding: 0.3rem 0.9rem;
  font-size: 0.7rem;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  border: 1px solid #FFF;
  color: #FFF;
  font-weight: 500;
  text-align: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: radial-gradient(1754.4% 116.02% at 0% 0%, rgba(64, 85, 185, 0.5) 0%, rgba(64, 85, 185, 0) 100%);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #FFF;
}
.get-started img {
  width: 20px;
}
.get-started:hover {
  background-color: #333;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}
.get-started:active {
  background-color: #0d0d0d;
  transform: translateY(1px);
  box-shadow: none;
}
.get-started:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.4);
}

.belowNavbarWrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 0.2rem 4%;
}
.belowNavbarWrapper .salesBarWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
}
.belowNavbarWrapper .salesLabel {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 0.7rem;
}
.belowNavbarWrapper .progressBar {
  min-width: 100px;
  width: 30%;
  border-radius: 20px;
  background: rgba(217, 217, 217, 0.3);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.15) inset;
  height: 0.5rem;
  border-radius: 35px;
}
.belowNavbarWrapper .seedround {
  width: -moz-fit-content;
  width: fit-content;
  color: #FFF;
  text-align: center;
  font-weight: 400;
  border: 1px solid #FFF;
  background: radial-gradient(1754.4% 116.02% at 0% 0%, rgba(217, 217, 217, 0.3) 0%, rgba(217, 217, 217, 0.12) 100%);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  white-space: nowrap;
  padding: 0.1rem 0.3rem;
  border-radius: 30px;
  font-size: 0.5rem;
}

@media screen and (min-width: 700px) {
  .navbar {
    padding: 1.2rem 5%;
  }
  .navbar .logo {
    width: 4%;
  }
  .navbar .links {
    -moz-column-gap: 15%;
         column-gap: 15%;
  }
  .navbar .links a {
    font-size: 1.2rem;
  }
  .navbar .links .get-started {
    font-size: 1.2rem;
  }
  .navbar .links .get-started img {
    width: 30px;
  }
  .belowNavbarWrapper {
    padding: 0.5rem 3%;
  }
  .belowNavbarWrapper .seedround {
    font-size: 1.2rem;
    padding: 0.5rem 1.2rem;
  }
  .belowNavbarWrapper .salesBarWrapper .salesLabel {
    font-size: 1.2rem;
  }
}